import {Injectable} from '@angular/core';


@Injectable()
export class TempStorage {
    
    company_id: string='';
    email_id: string='';
    

}
