import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchpipe',
})
export class SearchPipe implements PipeTransform {

    constructor(){}

    transform(value: any, search: string, searcharea: string[]): any {
        if (search !== undefined) {
            const regex = new RegExp(search, 'i');
            const data = [];
            value.forEach(element => {
                let flag = false;
                if(searcharea && searcharea.length){
                    searcharea.forEach(ele => {
                        var main = ele.split('.');
                        if(typeof element[ele] === 'object'){
                            var langIndex = 0;
                            if (element[ele][langIndex] && element[ele][langIndex].match(regex)) {
                                flag = true;
                            }
                        }
                        else{
                            if ( main.length > 1 ? element[main[0]][main[1]].match(regex) : element[main[0]].match(regex)) {
                                // flag = element[ele][langIndex].match(regex);
                                flag = true;
                            }
                        }
                    });
                }else{
                    flag = element.toString().match(regex);
                }
                if (flag) {
                    data.push(element);
                }
            });
            return data;
        }
        return value;
    }
}