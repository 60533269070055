export var REQUEST_STATE = {
    WAITING_FOR_ACCEPT: 0,
	ACCEPTED: 1,
	PROVIDER_COMING: 3,
	PROVIDER_ARRIVED: 5,
	JOB_STARTED: 7,
	COMPLETED: 10,
	NO_PROVIDER_FOUND: 100,
	REJECTED: 101,
	CANCELLED_BY_USER: 103,
	CANCELLED_BY_COMPANY: 106,
	CANCELLED_BY_PROVIDER: 105,
	CANCELLED_BY_ADMIN: 107,
	REJECT_BY_PROVIDER:108
}

export var PAYOUT_STATUS = {
	ON_HOLD: 1,
	CONFIRMED: 2
}

export var REQUEST_STATUS_ID = {
	IDEAL: 0,
	RUNNING: 1,
	CANCELLED: 3,
	COMPLETED: 10
}

export var PAYMENT_TYPE = {
    WALLET: 1,
    CASH: 2,
    STRIPE: 3,
	PAYSTACK:5
} 

export var USER_TYPE = {
    ADMIN:	1,
	SUB_ADMIN:	3,
	USER:	7,
	COMPANY:	9,
	PROVIDER:	8
};

export var SETTLED_BY = {
	REMAINING: 0,
	WALLET: 1,
	CASH: 2,
	CARD: 3
}

export var CONSTANT = {

	IMAGE_BASE_URL: 'https://admin.wefix.today/',
	BASE_URL: 'https://admin.wefix.today',
	TO_FIXED: 2
}