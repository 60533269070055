export var menu_title = {

}

export var status = {
	waiting_for_accept: 'waiting_for_accept',
	accepted: 'accepted',
	provider_coming: 'provider_coming',
	provider_arrived: 'provider_arrived',
	job_started: 'job_started',
	completed: 'completed',
	no_provider_found: 'no_provider_found',
	rejected: 'rejected',
	cancelled: 'cancelled',
	cancelled_by_user: 'cancelled_by_user',
	cancelled_by_provider: 'cancelled_by_provider',
	cancelled_by_admin: 'cancelled_by_admin',
	cancelled_by_company: 'cancelled_by_company',
	reject_by_provider:'reject_by_provider'

}

export var title = {
	cancel_reason:"cancel_reason",
	defalut_payment:"defalut_payment",
	no_data_found:'No Data Found',
	sign_out:'sign_out',
	upload_file:"upload_file",
	fix:"Fixed",
	delete: "Delete",
	fix_hourly:"Fixed + Hourly",
	invalid_email_address: "Please enter valid email",
	invalid_username_length: "Please Enter Min 6 Characters",
	image_too_large: "Please Select Image Size With Less Then 900KB",
	search: 'search',
	item_per_page: 'Data per page',
	payment_type: 'payment_type',
	go_to_page: 'go_to_page',
	select_status: 'select_status',
	payment_mode: 'payment_mode',
	start_date: 'start_date',
	end_date: 'end_date',
	city_name: 'city_name',
	latitude: 'latitude',
	longitude: 'longitude',
	all: 'all',
	download_user_string:"Download user string",
	download_admin_string:"Download admin string",
	company: 'company',
	city_radius: 'city_radius',
	is_use_radius: 'is_use_radius',
	is_business: 'is_business',
	is_promo_apply: 'is_promo_apply',
	is_zone_business: 'is_zone_business',
	is_cash_payment_mode: 'is_cash_payment_mode',
	is_other_payment_mode: 'is_other_payment_mode',
	is_wallet_payment: 'is_wallet_payment',
	is_provider_can_register_by_app: 'Is Provider Can Register By App?',
	is_check_provider_wallet_amount_for_received_cash_request: 'is_check_provider_wallet_amount_for_received_cash_request',
	provider_min_wallet_amount_for_received_cash_request: 'provider_min_wallet_amount_for_received_cash_request',
	auto_transfer_timeout: 'auto_transfer_timeout',
	sunday: "sunday",
	monday: "monday",
	tuesday: "tuesday",
	wednesday: "wednesday",
	thursday: "thursday",
	friday: "friday",
	saturday: "saturday",
	is_auto_transfer: 'is_auto_transfer',
	auto_transfer_day: 'auto_transfer_day',
	is_check_provider_wallet_amount_for_received_request: 'is_check_provider_wallet_amount_for_received_request',
	provider_max_cash_on_hand: 'provider_max_cash_on_hand',
	is_provider_earning_add_in_wallet_on_cash_payment: 'is_provider_earning_add_in_wallet_on_cash_payment',
	is_provider_earning_add_in_wallet_on_other_payment: 'is_provider_earning_add_in_wallet_on_other_payment',

	country_name: 'country_name',
	country_code: 'country_code',
	country_code_2: 'country_code_2',
	currency_code: 'currency_code',
	currency_sign: 'currency_sign',
	country_phone_code: 'country_phone_code',
	maximum_phone_number_length: 'maximum_phone_number_length',
	minimum_phone_number_length: 'minimum_phone_number_length',
	is_distance_unit_mile: 'is_distance_unit_mile',
	is_referral_user: 'is_referral_user',
	referral_bonus_to_user: 'referral_bonus_to_user',
	referral_bonus_to_user_friend: 'referral_bonus_to_user_friend',
	referral_bonus_receive_after_no_of_request: 'referral_bonus_receive_after_no_of_request',	
	is_referral_provider: 'is_referral_provider',
	no_of_user_use_referral: 'no_of_user_use_referral',
	referral_bonus_to_provider: 'referral_bonus_to_provider',
	referral_bonus_to_provider_friend: 'referral_bonus_to_provider_friend',
	no_of_provider_use_referral: 'no_of_provider_use_referral',
	select_country: 'select_country',
	currency_rate: 'currency_rate',
	select_timezone: 'select_timezone',

	name: 'name',
	description: 'description',
	
	user_string_file: 'user_string_file',
	admin_string_file: 'admin_string_file',

	select_city: 'select_city',
	select_service: 'select_service',
	is_include_tax: 'is_include_tax',
	tax: 'tax',
	admin_profit_type: 'admin_profit_type',
	admin_profit_value: 'admin_profit_value',

	price_type: 'price_type',
	fixed_price_hour: 'fixed_price_hour',
	fixed_price: 'fixed_price',
	extra_price_for_hour: 'extra_price_for_hour',
	max_extra_hour: 'max_extra_hour',
	fixed: 'fixed',
	fixed_hourly: 'fixed_hourly',
	hourly_slot: 'hourly_slot',
	select_price_type: 'select_price_type',
	start_hour: "start_hour",
	end_hour: "end_hour",
	price: "price",
	is_provider_can_set_price: "is_provider_can_set_price",
	is_user_can_choose_multiple_sub_service: "is_user_can_choose_multiple_sub_service",
	is_user_can_choose_multiple_service: "is_user_can_choose_multiple_service",

	email: "email",
	wallet: "wallet",
	phone: "phone",
	created_request: "created_request",
	completed_request: "completed_request",
	user_register: "user_register",
	provider_register: "provider_register",
	company_register: "company_register",
	enter_wallet_amount: 'enter_wallet_amount',
	referral_code: 'referral_code',

	contact_us_phone: 'contact_us_phone',
	contact_us_email: 'contact_us_email',
	is_show_coming_status: 'is_show_coming_status',
	is_show_arrived_status: 'is_show_arrived_status',
	is_ask_user_to_enter_email: 'is_ask_user_to_enter_email',
	is_user_email_verification: 'is_user_email_verification',
	is_user_phone_verification: 'is_user_phone_verification',
	is_user_can_use_social: 'is_user_can_use_social',
	is_user_can_login_using_verification_code: 'is_user_can_login_using_verification_code',
	is_user_can_login_using_password: 'is_user_can_login_using_password',
	otp_length: 'otp_length',
	round_number_up_to: 'round_number_up_to',
	is_schedule_request: 'is_schedule_request',
	schedule_time_gap: 'schedule_time_gap',
	schedule_request_create_after_minute: 'schedule_request_create_after_minute',

	is_provider_email_verification: 'is_provider_email_verification',
	is_provider_phone_verification: 'is_provider_phone_verification',
	is_provider_can_use_social: 'is_provider_can_use_social',
	is_provider_can_login_using_verification_code: 'is_provider_can_login_using_verification_code',
	is_provider_can_login_using_password: 'is_provider_can_login_using_password',


	android_user_app_google_key: 'android_user_app_google_key',
	android_provider_app_google_key: 'android_provider_app_google_key',
	android_store_app_google_key: 'android_store_app_google_key',
	ios_user_app_google_key: 'ios_user_app_google_key',
	ios_provider_app_google_key: 'ios_provider_app_google_key',
	ios_store_app_google_key: 'ios_store_app_google_key',

	access_key_id: 'access_key_id',
	secret_key_id: 'secret_key_id',
	aws_bucket_name: 'aws_bucket_name',
	image_base_url: 'image_base_url',
	is_use_aws_bucket: 'is use aws bucket',

	android_provider_app_gcm_key: 'android_provider_app_gcm_key',
	android_user_app_gcm_key: 'android_user_app_gcm_key',
	stripe_public_key: 'stripe_public_key',
	stripe_secret_key: 'stripe_secret_key',
	paystack_public_key: 'paystack_public_key',
	paystack_secret_key: 'paystack_secret_key',

	android_user_app_version_code: 'android_user_app_version_code',
	is_android_user_app_open_update_dialog: 'is_android_user_app_open_update_dialog',
	is_android_user_app_force_update: 'is_android_user_app_force_update',
	android_provider_app_version_code: 'android_provider_app_version_code',
	is_android_provider_app_open_update_dialog: 'is_android_provider_app_open_update_dialog',
	is_android_provider_app_force_update: 'is_android_provider_app_force_update',
	ios_user_app_version_code: 'ios_user_app_version_code',
	is_ios_user_app_open_update_dialog: 'is_ios_user_app_open_update_dialog',
	is_ios_user_app_force_update: 'is_ios_user_app_force_update',
	ios_provider_app_version_code: 'ios_provider_app_version_code',
	is_ios_provider_app_open_update_dialog: 'is_ios_provider_app_open_update_dialog',
	is_ios_provider_app_force_update: 'is_ios_provider_app_force_update',

	provider_bundle_id: 'provider_bundle_id',
	user_bundle_id: 'user_bundle_id',
	key_id: 'key_id',
	team_id: 'team_id',
	is_production: 'is_production',

	cash: 'cash',
	stripe: 'stripe',
	paypal: 'paypal',
	paystack:'paystack',

	on_hold: 'on_hold',
	confirmed: 'confirmed',
	proceed: 'proceed',
	payout_amount: 'payout_amount',
	hold_amount: 'hold_amount',
	payouts: 'payouts',
	total_completed: 'total_completed',
	total_cancelled: 'total_cancelled',
	total: 'total',
	cash_payment: 'cash_payment',
	card_payment: 'card_payment',
	other_payment: 'other_payment',
	wallet_payment: 'wallet_payment',
	provider_profit: 'provider_profit',
	admin_profit: 'admin_profit',
	pay_to_provider: 'pay_to_provider',
	collect_from_provider: 'collect_from_provider',
	settled_amount: 'settled_amount',
	remaining_amount: 'remaining_amount',
	remaining: 'remaining',
	settlement: 'settlement',
	settled: 'settled',
	card: 'card',
	select_settlement_type: 'select_settlement_type',

	theme_color: 'theme_color',
	text_dark_color: 'text_dark_color',
	text_light_color: 'text_light_color',
	text_hint_color: 'text_hint_color',
	button_background_color: 'button_background_color',
	button_text_color: 'button_text_color',
	delete_button_color: 'delete_button_color',

	total_request: 'total_request',
	revenue: 'revenue',
	profit: 'profit',
	new_user: 'new_user',
	new_provider: 'new_provider',
	users_and_providers: 'users_and_providers',
	requests: 'requests',
	users_request_analytics: 'users_request_analytics',
	promo_payment: 'promo_payment',

	username: 'Username',
	password: 'Password',
	date_filter: 'date_filter',
	today:'today',
	yesterday:'yesterday',
	last_7_days:'last_7_days',
	last_28_days:'last_28_days',
	last_30_days:'last_30_days',
	custom:'custom',
	completed:'completed',
	cancelled:'cancelled',
	total_user:'total_user',
	total_provider:'total_provider',
	user:'user',
	first_time_user:'first_time_user',
	approved:'approved',
	approve:'approve',
	declined:'declined',
	decline:'decline',
	export:'export',
	edit_user:'edit_user',
	id:'id',
	action:'action',
	city:'city',
	document:'document',
	edit_provider:'edit_provider',
	view_documents:'view_documents',
	country:'country',
	service:'service',
	expiry_date:'expiry_date',
	status:'status',
	date:'date',
	payment_detail:'payment_detail',
	service_payment:'service_payment',
	invoice:'invoice',
	detail:'detail',
	single:'single',
	group:'group',
	pay_collect:'pay_collect',
	settlement_by:'settlement_by',
	type:'type',
	added_amount:'added_amount',
	total_amount:'total_amount',
	total_earning:'total_earning',
	
	settings:'settings',
	installation_settings:'installation_settings',
	app_settings:'app_settings',
	color_settings:'color_settings',
	language:'language',
	add_language:'add_language',
	add_new_language:'add_new_language',
	enter_language:'enter_language',
	enter_code:'enter_code',
	mandatory:'mandatory',
	email_unique_title: 'email_unique_title',
	email_title: 'email_title',
	email_content: 'email_content',
	is_send: 'is_send',
	nexmo_name: 'nexmo_name',
	nexmo_api_key: 'nexmo_api_key',
	nexmo_secret_key: 'nexmo_secret_key',
	sms_unique_title: 'sms_unique_title',
	sms_content: 'sms_content',


	tax_included: 'tax_included',
	tax_excluded: 'tax_excluded',
	provider_detail: 'provider_detail',
	user_detail: 'user_detail',
	provider: 'provider',
	notification_type: 'notification_type',
	notification_content: 'notification_content',
	request: "request",
	document_name: 'document_name',
	document_for: 'document_for',
	is_unique_code: 'is_unique_code',
	is_expired_date: 'is_expired_date',
	is_mandatory: 'is_mandatory',
	yes: "yes",
	no: "no",
	date_required:"Date Required"
}

export var validation_message = {
	uname_required:'Username is Required',
	password_required:'Password is Required',
	min_max_length_validate:'Phone min and max length between 6 to 16 only!',
	please_enter_valid_data:'Please remove space',
	enter_only_number:'enter_only_number',
	please_validate: 'please_validate',
	this_field_is_required: 'This Field Is Required',
	city_already_exist: 'City Already Exist',
	please_draw_boundary_first: 'Please Draw Boundary First',
	phone_number_short:'phone_number_short',
	please_select_payment_method: 'Please Select Atleast One Payment Method',
	service_already_added: 'Service is existing with this name',
	Please_add_verison:'Please add verison in this format 1.0.0'
}

export var button = {
	add_city: 'add_city',
	add_country: 'add_country',
	submit: 'submit',
	update: 'update',
	add_service: 'add_service',
	edit_service: 'edit_service',
	add_sub_service: 'add_sub_service',
	add: 'add',
	add_slot: 'add_slot',
	cancel: 'cancel',
	detail: 'detail',
	clear: 'clear',
	absolute:'absolute',
	percentage:'percentage'
}

export var heading_title = {
	add_city_data: 'add_city_data',
	service_list: 'service_list',
	sub_service_list: 'sub_service_list',
	service_detail: 'service_detail',
	sub_service_detail: 'sub_service_detail',
	admin_setting: 'admin_setting',
	user_setting: 'user_setting',
	provider_setting: 'provider_setting',
	s3_bucket_setting: 's3_bucket_setting',
	google_key: 'Google Key',
	payment_gateway: 'payment_gateway',
	stripe_key: 'stripe_key',
	paystack_key: 'paystack_key',
	gcm_key: 'gcm_key',
	user_app_force_update: 'user_app_force_update',
	provider_app_force_update: 'provider_app_force_update',
	ios_push_setting: 'ios_push_setting',
	sub_services: 'sub_services',
	status_detail: 'status_detail',
	invoice_detail: 'invoice_detail',
	user_app: 'user_app',
	provider_app: 'provider_app',
	website: 'website',
	is_document_uploaded: 'is_document_uploaded'
}
